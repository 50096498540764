<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Archived application update</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Archived application update
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="12" md="3">
                  <v-text-field
                      label="Search by name"
                      v-model="search.info"
                      v-on:keyup.enter="searchUpdateRequest"
                      @input="search.info = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-menu v-model="request_date_menu" :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field  v-on:keyup.enter="searchUpdateRequest"
                                     v-model="search.request_date"
                                     label="Date of request" clearable
                                     prepend-inner-icon="mdi-calendar"
                                     readonly dense outlined
                                     v-bind="attrs"
                                     v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.request_date"
                        no-title
                        :min="currentDate"
                        @input="request_date_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-select
                      label="Status"
                      v-model="search.status"
                      v-on:keyup.enter="searchUpdateRequest"
                      @input="search.status = $event !== null ? $event : ''"
                      item-text="title"
                      item-value="value"
                      :items="status"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>


                <v-col cols="12" sm="12" md="2">
                  <v-btn
                      @click="searchUpdateRequest"
                      class="text-white btn btn-primary"
                      :loading="isLoading">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>
            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>
              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="! isLoading">
                <thead>
                <tr class="px-3">
                  <th v-if="currentUser && currentUser.access_type == 'head'">Branch</th>
                  <th>Date Requested</th>
                  <th>Requested By</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th class="px-3 text-center">Action</th>
                </tr>
                </thead>

                <template>
                  <tr v-for="(item, index) in update_requests" :key="index" v-if="update_requests.length>0">
                    <td class="px-2" v-if="currentUser && currentUser.access_type == 'head'">
                      {{item.branch_name}}
                    </td>
                    <td>
                        <span @click="reviewRequest(item)" class="ml-2 text-primary cursor-pointer font-weight-medium d-block font-size-lg">
                       {{ item.requested_date }}
                      </span>
                    </td>
                    <td>
                        <span class="ml-2 font-weight-bolder d-block font-size-lg">
                        {{ item.full_name }}
                      </span>
                    </td>
                    <td>
                        <span class="font-weight-black d-block font-size-lg">
                        {{ item.type_text }}
                      </span>
                    </td>
                    <td>
                           <span class="badge badge-success text-lg`"
                                 v-bind:class="{ 'badge-success': item.status=='approved', 'badge-danger': item.status=='rejected','badge-warning':item.status=='received' }">
                             {{ item.status_text }}
                           </span>
                    </td>

                   <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="reviewRequest(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-info-circle</v-icon>
                                  </span>
                                <span class="navi-text">Review Request</span>
                              </a>
                            </b-dropdown-text>

                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="update_requests.length == 0">
                    <td class="text-center px-3" colspan="5">No items found.</td>
                  </tr>
                </template>

              </table>

              <b-pagination
                  v-if="update_requests.length > 0"
                  class="pull-right mt-7"
                  @input="getAllUpdateRequest"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  :disabled="isLoading"
                  last-number
              ></b-pagination>
            </div>
            <v-dialog
                v-model="dialog"
                max-width="800px" transition="dialog-bottom-transition" persistent>
              <v-card>
                <v-card-title>
                  <span ><span class="h5 font-weight-regular">This request was made on <strong>{{updateRequestDetail.requested_date}}</strong> by <strong>{{updateRequestDetail.full_name}}</strong></span></span>
                </v-card-title>
                <v-card-text>
                  <v-list>
                    <template>
                      <v-list-item>
                        <v-list-item-title>
                          <p class="font-weight-regular " v-html="updateRequestDetail.content"></p>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                  <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <b>Remarks: </b>
                          <p class="font-weight-regular " v-html="updateRequestDetail.remark"></p>
                        </v-list-item-title>
                      </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn :loading="isLoading"
                         text color="red"
                         x-large
                         @click="dialog = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import UpdateRequestService from "@/services/update-request/UpdateRequestService";
const updateRequest=new UpdateRequestService;
export default {
  data(){
    return{
      update_requests:[],
      search:{
        type:'',
        status:'',
        info:'',
        request_date:'',
      },
      total: null,
      perPage: null,
      page: null,
      isLoading:false,
      dialog:false,
      updateRequestDetail: {},
      request_date_menu:false,
      currentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      status:[
        {value:'approved',title:'Approved'},
        {value:'rejected',title:'Not approved'},
      ],
    }
  },
  methods:{
    searchUpdateRequest() {
      this.getAllUpdateRequest();
    },
    reviewRequest(updateRequest) {
      this.dialog = true;
      this.updateRequestDetail = updateRequest;
    },
    getAllUpdateRequest() {
      this.isLoading = true;
      updateRequest
          .archived(this.search, this.page)
          .then(response => {
            this.update_requests=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading = false;
          })
          .catch((err) => {

          });
    },
  },
  mounted() {
    this.getAllUpdateRequest();
  }
}
</script>